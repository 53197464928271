import {Routes} from '@angular/router';
import {HomeComponent} from "./page/home/home.component";

export const routes: Routes = [
    {path: '', pathMatch: 'full', component: HomeComponent,},
    {
        path: 'inputs', children: [
            {path: 'text', loadChildren: () => import('./page/inputs/text').then(m => m.ROUTES), data: {title: 'Textfields'}},
            {path: 'number', loadChildren: () => import('./page/inputs/number').then(m => m.ROUTES), data: {title: 'Numberfields'}},
            {path: 'select', loadChildren: () => import('./page/inputs/select').then(m => m.ROUTES), data: {title: 'Selectboxes'}},
            {path: 'checkbox', loadChildren: () => import('./page/inputs/checkbox').then(m => m.ROUTES), data: {title: 'Checkboxes'}},
            {path: 'toggle', loadChildren: () => import('./page/inputs/toggle').then(m => m.ROUTES), data: {title: 'Toggles'}},
            {path: 'textarea', loadChildren: () => import('./page/inputs/textarea').then(m => m.ROUTES), data: {title: 'Textareas'}},
            {path: 'radio', loadChildren: () => import('./page/inputs/radio').then(m => m.ROUTES), data: {title: 'Radiobuttons'}},
        ],
    },
    {
        path: 'controls', children: [
            {path: 'button', loadChildren: () => import('./page/controls/button').then(m => m.ROUTES), data: {title: 'Buttons'}}
        ],
    },
    {
        path: 'libs', children: [
            {path: 'hotkeys', loadChildren: () => import('./page/libs/hotkeys').then(m => m.ROUTES), data: {title: 'Hotkeys'}}
        ],
    },
    {path: '**', redirectTo: ''},
];
