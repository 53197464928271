import {Component} from '@angular/core';
import {DemoWrapperComponent} from "../../shared/demo-wrapper/demo-wrapper.component";
import {ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'if-demo-home',
  standalone: true,
    imports: [
        DemoWrapperComponent,
        ReactiveFormsModule
    ],
  templateUrl: './home.component.html',
  styles: ``
})
export class HomeComponent {

}
