<header class="relative isolate pt-16">
    <div class="absolute inset-0 -z-10 overflow-hidden" aria-hidden="true">
        <div class="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
            <div class="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#ff7701] to-[#ff8f0a]"
                 style="clip-path: polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)"></div>
        </div>
        <div class="absolute inset-x-0 bottom-0 h-px bg-gray-900/5 dark:bg-gray-50/5"></div>
    </div>

    <div class="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8">
        <div class="mx-auto flex max-w-2xl items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
            <div class="flex items-center gap-x-6">
                <h1>
                    <div class="text-sm leading-6 text-gray-500 dark:text-slate-400">Library</div>
                    <div class="mt-1 text-base font-semibold leading-6 text-gray-900 dark:text-slate-100">&#64;if/angular-core-components
                    </div>
                </h1>
            </div>
        </div>
    </div>
</header>

<div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
    <div class="flex flex-col items-start gap-x-8 gap-y-8 lg:mx-0">
        <div class="w-full -mx-4 px-4 py-8 shadow-sm ring-1 ring-gray-900/5 dark:ring-gray-50/5 sm:mx-0 rounded-lg sm:px-8 sm:pb-14 xl:px-16 xl:pb-20 xl:pt-16 text-gray-500 dark:text-slate-400">
            <h2 class="text-base font-semibold leading-6 text-gray-900 dark:text-slate-100">Einleitung</h2>
            <p>Folgende Library dient dazu, einen Kern von Components zu schaffen. Dazu lassen sich die Components
                direkt verwenden oder überschreiben, um diese auf den eigenen Stil anzupassen.</p>

            <h2 class="text-base font-semibold leading-6 mt-6 text-gray-900 dark:text-slate-100">Installation</h2>
            <p>Über npm:</p>
            <pre class="bg-gray-900/5 dark:bg-gray-50/5 rounded-lg p-4 text-sm font-mono mb-2 overflow-auto"><code>npm install &#64;if/angular-core-components</code></pre>
            <p>Über pnpm:</p>
            <pre class="bg-gray-900/5 dark:bg-gray-50/5 rounded-lg p-4 text-sm font-mono mb-2 overflow-auto"><code>pnpm add &#64;if/angular-core-components</code></pre>
            <p>Über yarn:</p>
            <pre class="bg-gray-900/5 dark:bg-gray-50/5 rounded-lg p-4 text-sm font-mono mb-2 overflow-auto"><code>yarn add &#64;if/angular-core-components</code></pre>
            <p>Über Bun</p>
            <pre class="bg-gray-900/5 dark:bg-gray-50/5 rounded-lg p-4 text-sm font-mono mb-2 overflow-auto"><code>bun add &#64;if/angular-core-components</code></pre>

            <h2 class="text-base font-semibold leading-6 mt-6 text-gray-900 dark:text-slate-100">Verwendung</h2>
            <p>Importieren Sie das Module in Ihrer Angular-Applikation:</p>
            <pre class="bg-gray-900/5 dark:bg-gray-50/5 rounded-lg p-4 text-sm font-mono mb-2 overflow-auto"><code>import &#123; IfCoreInputComponents &#125; from '&#64;if/angular-core-components';</code></pre>

            <p>Wichtig, es muss dabei die tailwind.config.js angepasst werden. Dabei muss folgendes in Content mit eingefügt werden:</p>
            <pre class="bg-gray-900/5 dark:bg-gray-50/5 rounded-lg p-4 text-sm font-mono mb-2 overflow-auto"><code>"node_modules/&#64;if/**/*.&#123;html,ts,mjs&#125;"</code></pre>

            <h2 class="text-base font-semibold leading-6 mt-6 text-gray-900 dark:text-slate-100">Overwrites</h2>

            <p>Um die Components zu überschreiben, können Sie die Components in Ihrer Angular-Applikation erben und
                überschreiben. Anschließend kann man das HTML nach seinen Wünschen anpassen:</p>

            <pre class="bg-gray-900/5 dark:bg-gray-50/5 rounded-lg p-4 text-sm font-mono mb-2 overflow-auto"><code>export class MyInputComponent extends IfCoreInputComponent &#123;...</code></pre>
        </div>

    </div>
</div>